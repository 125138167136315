import { formatVND } from '@Utils/helpers';
import { SHIPPING_COMPANY } from '@Lib/constant';

export const getWarehouseName = (slug) => {
  const objWarehouse = {
    dat_hang: 'Đặt hàng',
    thiet_ke: 'Thiết kế',
    co_san: 'Có sẵn',
  };
  return slug ? objWarehouse[slug] : '';
};

export const getCategoryName = (slug) => {
  const objCategory = {
    quan_ao: 'Quần áo nội địa',
    giay_dep: 'Giày dép nội địa',
    tui_xach: 'Túi xách nội địa',
    phu_kien: 'Phụ kiện nội địa',
    quan_ao_thuong_hieu: 'Quần áo thương hiệu',
    giay_dep_thuong_hieu: 'Giày dép thương hiệu',
    tui_xach_thuong_hieu: 'Túi xách thương hiệu',
    phu_kien_thuong_hieu: 'Phụ kiện thương hiệu',
    thoi_trang_thiet_ke: 'Thời trang thiết kế',
  };
  return slug ? objCategory[slug] : '';
};

export const getShippingCompanyImage = (id) => {
  const item = SHIPPING_COMPANY.find((item) => item.value === id);
  return item?.url;
};

export const getShippingCompanyName = (id) => {
  const item = SHIPPING_COMPANY.find((item) => item.value === id);
  return item?.label ?? '-';
};

export const getShippingCompany = (id) => {
  return SHIPPING_COMPANY.find((item) => item.value === id);
};

export const getColorAndIconByStatusId = (statusId) => {
  let data;
  switch (statusId) {
    case 0:
      data = {
        icon: '/images/order-icon/all.svg',
        iconActive: '/images/order-icon/all-active.svg',
        color: '#EB5757',
      };
      break;
    case 1:
      data = {
        icon: '/images/order-icon/new.svg',
        iconActive: '/images/order-icon/new-active.svg',
        color: '#DE5E57',
      };
      break;
    case 2:
      data = {
        icon: '/images/order-icon/request-confirmation.svg',
        iconActive: '/images/order-icon/request-confirmation-active.svg',
        color: '#785BBC',
      };
      break;
    case 3:
      data = {
        icon: '/images/order-icon/confirmed.svg',
        iconActive: '/images/order-icon/confirmed-active.svg',
        color: '#4253AF',
      };
      break;
    case 4:
      data = {
        icon: '/images/order-icon/order-request.svg',
        iconActive: '/images/order-icon/order-request-active.svg',
        color: '#4896EC',
      };
      break;
    case 5:
      data = {
        icon: '/images/order-icon/ordering.svg',
        iconActive: '/images/order-icon/ordering-active.svg',
        color: '#4CAABE',
      };
      break;
    case 6:
      data = {
        icon: '/images/order-icon/ordered.svg',
        iconActive: '/images/order-icon/ordered-active.svg',
        color: '#52A49A',
      };
      break;
    case 7:
      data = {
        icon: '/images/order-icon/arrived-in-stock.svg',
        iconActive: '/images/order-icon/arrived-in-stock-active.svg',
        color: '#759D48',
      };
      break;
    case 8:
      data = {
        icon: '/images/order-icon/preparing.svg',
        iconActive: '/images/order-icon/preparing-active.svg',
        color: '#9E9C3D',
      };
      break;
    case 9:
      data = {
        icon: '/images/order-icon/composed.svg',
        iconActive: '/images/order-icon/composed-active.svg',
        color: '#E68538',
      };
      break;
    case 10:
      data = {
        icon: '/images/order-icon/packing.svg',
        iconActive: '/images/order-icon/packing-active.svg',
        color: '#52A49A',
      };
      break;
    case 11:
      data = {
        icon: '/images/order-icon/packed.svg',
        iconActive: '/images/order-icon/packed-active.svg',
        color: '#52A49A',
      };
      break;
    case 12:
      data = {
        icon: '/images/order-icon/shipped-out.svg',
        iconActive: '/images/order-icon/shipped-out-active.svg',
        color: '#C84E2A',
      };
      break;
    case 13:
      data = {
        icon: '/images/order-icon/delivered.svg',
        iconActive: '/images/order-icon/delivered-active.svg',
        color: '#52A49A',
      };
      break;
    case 14:
      data = {
        icon: '/images/order-icon/waiting-for-check.svg',
        iconActive: '/images/order-icon/waiting-for-check-active.svg',
        color: '#74574A',
      };
      break;
    case 15:
      data = {
        icon: '/images/order-icon/success.svg',
        iconActive: '/images/order-icon/success-active.svg',
        color: '#5AC462',
      };
      break;
    case 16:
      data = {
        icon: '/images/order-icon/out-of-stock.svg',
        iconActive: '/images/order-icon/out-of-stock-active.svg',
        color: '#667D8A',
      };
      break;
    case 17:
      data = {
        icon: '/images/order-icon/cancel.svg',
        iconActive: '/images/order-icon/cancel-active.svg',
        color: '#B14121',
      };
      break;
    case 18:
      data = {
        icon: '/images/order-icon/return.svg',
        iconActive: '/images/order-icon/return-active.svg',
        color: '#3A474E',
      };
      break;
    case 19:
      data = {
        icon: '/images/order-icon/return.svg',
        iconActive: '/images/order-icon/return-active.svg',
        color: '#AF6EC3',
      };
      break;
    case 20:
      data = {
        icon: '/images/order-icon/return.svg',
        iconActive: '/images/order-icon/return-active.svg',
        color: '#AF6EC3',
      };
      break;
    case 21:
      data = {
        icon: '/images/order-icon/return.svg',
        iconActive: '/images/order-icon/return-active.svg',
        color: '#AF6EC3',
      };
      break;
    case 22:
      data = {
        icon: '/images/order-icon/return.svg',
        iconActive: '/images/order-icon/return-active.svg',
        color: '#AF6EC3',
      };
      break;
    default:
      break;
  }
  return data;
};

export const getTotalPriceOrderByStatus = (products, statusId) => {
  const totalPrice = products.reduce((prev, next) => {
    if (next.pivot.status_id === statusId || statusId === 0) {
      const price = next.pivot.payable_amount;
      prev += price;
    }
    return prev;
  }, 0);
  return formatVND(totalPrice);
};

export const getDepositType = (item) => {
  if (item.discount_percentage > 0) {
    return '(%)';
  }
  if (item.discount_amount > 0) {
    return '(đ)';
  }
  return '';
};

export const getDiscount = (item) => {
  const { price, discount_percentage, discount_amount, quantity } = item;
  let discount = 0;
  if (discount_percentage > 0) {
    discount = (price * discount_percentage) / 100;
  } else {
    discount = discount_amount / quantity;
  }
  return Math.round(discount);
};

export const getInventoryPrice = (item) => {
  const { inventory_buy_source_price, inventory_buy_original_price } = item;
  return inventory_buy_source_price - inventory_buy_original_price;
};

export const groupOrderStatus = () => {
  return [
    {
      name: 'Tất cả',
      icon: '/images/order-icon/all.svg',
      iconActive: '/images/order-icon/all-active.svg',
      color: '#EB5757',
      childId: [],
      child: [],
      isOnlyAdmin: true,
    },
    {
      name: 'Xác nhận',
      icon: '/images/order-icon/request-confirmation.svg',
      iconActive: '/images/order-icon/request-confirmation-active.svg',
      color: '#EB5757',
      childId: [1, 2, 3, 4],
      child: [],
    },
    {
      name: 'Đặt hàng',
      icon: '/images/order-icon/ordered.svg',
      iconActive: '/images/order-icon/ordered-active.svg',
      color: '#EB5757',
      childId: [5, 6, 7],
      child: [],
    },
    {
      name: 'In & Đóng gói',
      icon: '/images/order-icon/packed.svg',
      iconActive: '/images/order-icon/packed-active.svg',
      color: '#EB5757',
      childId: [9, 10, 11],
      child: [],
    },
    {
      name: 'Vận chuyển',
      icon: '/images/order-icon/ordering.svg',
      iconActive: '/images/order-icon/ordering-active.svg',
      color: '#EB5757',
      childId: [12, 13, 14],
      child: [],
    },
    {
      name: 'Cần xử lý',
      icon: '/images/order-icon/order-request.svg',
      iconActive: '/images/order-icon/order-request-active.svg',
      color: '#EB5757',
      childId: [17, 19, 20, 18],
    },
    {
      name: 'Thanh toán',
      icon: '/images/order-icon/composed.svg',
      iconActive: '/images/order-icon/composed-active.svg',
      color: '#EB5757',
      childId: [15, 16],
      isOnlyAdmin: true,
    },
    {
      name: 'Huỷ',
      icon: '/images/order-icon/cancel.svg',
      iconActive: '/images/order-icon/cancel-active.svg',
      color: '#EB5757',
      childId: [21, 22],
      child: [],
    },
  ];
};

export const isShowStatusQuantity = ({ item, accessPermisions }) => {
  const { isOnlyAdmin } = item;
  if (!isOnlyAdmin) {
    return true;
  }
  const { see_orders_index_tab_count } = accessPermisions;
  return see_orders_index_tab_count;
};
