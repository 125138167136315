import shortid from 'shortid';
import { lazy } from 'react';

export const privateConstant = [
  {
    id: shortid.generate(),
    name: 'Tổng quan',
    path: '/dashboard',
    accessName: 'dashboard',
    component: lazy(() => import('../../views/Dashboard')),
    exact: true,
  },
  {
    id: shortid.generate(),
    name: 'Sản phẩm',
    isSubMenu: true,
    accessName: 'products',
    exact: true,
    child: [
      {
        id: shortid.generate(),
        name: 'ERP',
        path: '/products',
        component: lazy(() => import('../../views/Products')),
        exact: true,
      },
      {
        id: shortid.generate(),
        name: 'Szwego',
        path: '/products/szwego',
        component: lazy(() => import('../../views/SzwegoProducts')),
        exact: true,
      },
      {
        id: shortid.generate(),
        name: 'Nhà CC Szwego',
        path: '/products/szwego-provider',
        component: lazy(() => import('../../views/SzwegoProvider')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/products/szwego/:id',
        component: lazy(() => import('../../views/SzwegoProductDetail')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/products/szwego-provider/addnew',
        component: lazy(() => import('../../views/SzwegoProviderDetail')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/products/szwego-provider/:id',
        component: lazy(() => import('../../views/SzwegoProviderDetail')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/products/addnew/:projectId',
        component: lazy(() => import('../../views/ProductDetail')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/products/:projectId/:productId/',
        component: lazy(() => import('../../views/ProductDetail')),
        exact: true,
        hidden: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Khách hàng',
    path: '/customer',
    accessName: 'customers',
    child: [
      {
        id: shortid.generate(),
        path: '/customer',
        component: lazy(() => import('../../views/Customer')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/customer/addNew/:projectId',
        component: lazy(() => import('../../views/CustomerDetail')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/customer/:projectId/:id',
        component: lazy(() => import('../../views/CustomerDetail')),
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Đơn hàng',
    path: '/order',
    accessName: 'orders',
    child: [
      {
        id: shortid.generate(),
        path: '/order',
        component: lazy(() => import('../../views/Order')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/order/addNew/:projectId',
        accessName: 'create_orders',
        component: lazy(() => import('../../views/OrderDetail')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/order/:projectId/:id',
        component: lazy(() => import('../../views/OrderDetail')),
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Đặt hàng',
    accessName: 'inventory_buy',
    path: '/inventoryBuy',
    child: [
      {
        id: shortid.generate(),
        path: '/inventoryBuy',
        component: lazy(() => import('../../views/InventoryBuy')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/inventoryBuy/:projectId/:id',
        component: lazy(() => import('../../views/InventoryBuyDetail')),
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Kho hàng',
    path: '/inventory',
    accessName: 'inventory',
    child: [
      {
        id: shortid.generate(),
        path: '/inventory',
        component: lazy(() => import('../../views/Inventory')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/inventory/:projectId/:id',
        component: lazy(() => import('../../views/OrderDetail')),
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Vận chuyển',
    path: '/shipping',
    accessName: 'shipping',
    child: [
      {
        id: shortid.generate(),
        path: '/shipping',
        component: lazy(() => import('../../views/Shipping')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/shipping/:projectId/:id',
        component: lazy(() => import('../../views/OrderDetail')),
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Kho',
    isSubMenu: true,
    accessName: 'warehouse',
    child: [
      {
        id: shortid.generate(),
        name: 'Có sẵn',
        path: '/inventory-counts',
        component: lazy(() => import('../../views/InventoryCounts')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/inventory-counts/:id',
        component: lazy(() => import('../../views/InventoryCountsDetails')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        name: 'Nhập kho',
        path: '/reports/inventory-receipt',
        query: 'type=import',
        component: lazy(() => import('../../views/InventoryReceipt')),
        exact: true,
        // image: '/images/menu/add-to-cart.png',
      },
      {
        id: shortid.generate(),
        name: 'Xuất kho',
        path: '/reports/inventory-receipt',
        query: 'type=export',
        component: lazy(() => import('../../views/InventoryReceipt')),
        exact: true,
        // image: '/images/menu/remove-from-cart.png',
      },
      {
        id: shortid.generate(),
        name: 'Kiểm kho',
        path: '/inventory-check-receipts',
        component: lazy(() => import('../../views/InventoryCheckReceipt')),
        exact: true,
      },
      {
        id: shortid.generate(),
        name: 'Kiểm kho',
        path: '/inventory-check-receipts/:id',
        component: lazy(() => import('../../views/InventoryCheckReceiptDetail')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/inventory-check-receipts/:id/inventory-adjust',
        component: lazy(() =>
          import('../../views/InventoryCheckReceipt/component/InventoryAdjust'),
        ),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/inventory-check-receipts/:id/report',
        component: lazy(() => import('../../views/InventoryCheckReceipt/component/Report')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        name: 'Bù trừ kiểm kho',
        path: '/reports/inventory-receipt',
        query: 'type=inventory_adjust',
        component: lazy(() => import('../../views/InventoryReceipt')),
        exact: true,
      },
      {
        id: shortid.generate(),
        name: 'Chi tiết đơn kho xuất',
        path: '/reports/inventory-receipt-export/:id',
        // accessName: 'reports_inventory_receipts_export',
        component: lazy(() => import('../../views/InventoryReceiptDetail')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        name: 'Chi tiết đơn kho nhập',
        path: '/reports/inventory-receipt-import/:id',
        // accessName: 'reports_inventory_receipts_import',
        component: lazy(() => import('../../views/InventoryReceiptDetail')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        name: 'Xuất nhập kho theo sản phẩm',
        path: '/inventory-event',
        component: lazy(() => import('../../views/InventoryEvent')),
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Báo cáo',
    accessName: 'reports',
    exact: true,
    isSubMenu: true,
    child: [
      // {
      //   id: shortid.generate(),
      //   name: 'Vận đơn',
      //   path: '/reports/shipments',
      //   component: lazy(() => import('../../views/Shipment')),
      //   exact: true,
      //   image: '/images/menu/chart.svg',
      //   accessName: 'reports_shipments',
      // },
      {
        id: shortid.generate(),
        name: 'Biên bản bàn giao',
        path: '/reports/shippingReceipts',
        component: lazy(() => import('../../views/ShippingReceipt')),
        exact: true,
        image: '/images/menu/document_check.svg',
        accessName: 'reports_shipping_receipts',
      },
      {
        id: shortid.generate(),
        path: '/reports/shippingReceipts/addNew',
        component: lazy(() => import('../../views/ShippingReceiptDetail')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/reports/shippingReceipts/:id',
        component: lazy(() => import('../../views/ShippingReceiptDetail')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        name: 'Doanh thu',
        path: '/reports/revenue',
        accessName: 'reports_sale_stats',
        component: lazy(() => import('../../views/Revenue')),
        exact: true,
        image: '/images/menu/revenue.png',
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Cài đặt',
    path: '/settings',
    accessName: 'setup',
    exact: true,
    child: [
      {
        id: shortid.generate(),
        path: '/settings',
        component: lazy(() => import('../../views/Settings')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/settings/warehouseLocation/addNew',
        component: lazy(() =>
          import('../../views/Settings/components/WarehouseLocation/WarehouseDetail'),
        ),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/settings/warehouseLocation/:id',
        component: lazy(() =>
          import('../../views/Settings/components/WarehouseLocation/WarehouseDetail'),
        ),
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Demo',
    path: '/demo',
    hidden: true,
    component: lazy(() => import('../../views/Demo')),
    exact: true,
  },
  {
    id: shortid.generate(),
    name: 'Search',
    path: '/search',
    hidden: true,
    component: lazy(() => import('../../views/Search')),
    exact: true,
  },
];
